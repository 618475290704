import React from "react";

const Landing = () => {
  return (
    <div className="main_cont">
      <div className="vide_cont !justify-between">
        <div className="vide_head">
          <h1 className="vide_h1 pl-0">
            Discover and Meet
            <br /> with Content Creators
          </h1>
        </div>
        <div className="vide_img">
          <img src="hero_cal.png" alt="calendar" width="828" className="image" />
        </div>
      </div>
      <p className="font-white mt-16 font-[Nunito] text-2xl font-bold sm:mt-0 sm:text-3xl">
        Create Memorable Moments ✨
      </p>
      {/* <Creator /> */}
      {/* <div className="fanclub_descr_cont">
        <div className="desc_cont">
          <h1 className="head_h2">What is FanClub?</h1>
          <p className="para ">
            FanClub is a new social media platform revolutionizing how fans connect with their favorite
            Content Creators. You can discover and meet new people or more deeply connect with the people you
            already follow online. With FanClub, you can book time virtually or IRL to have personal, face to
            face engagements with Creators of your choosing, creating special moments and memories.
          </p>
        </div>
        <div className="desc_cont">
          <p className="para">
            With FanClub, you can set your available times for others to book time on your schedule. You’re
            able to set different meeting types for different durations and prices for any type of session you
            may offer- Meet N’ Greets, coaching/consulting sessions, readings, or just hanging out! You’re
            able to set questions and prompts for others to fill out and you can choose to accept or decline
            meeting requests. FanClub promotes more authentic relationships and deeper connections with
            Creators and people you know online.
          </p>
        </div>
      </div> */}
      <div className="about_sec mt-10">
        <div className="about_desc_cont">
          <div className="abt_card">
            <p className="p3">1:1</p>
            <p className="head_h2 clr">
              Meet face-to-
              <br />
              face
            </p>
          </div>
          <div className="abt_card">
            <p className="p3">$</p>
            <p className="head_h2 clr">
              Monetize your
              <br />
              influence
            </p>
          </div>
        </div>
        <div className="right_img">
          <img src="hero_img.png" loading="lazy" alt="hero image" className="img_2" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
